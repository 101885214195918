import { makeAutoObservable } from 'mobx';
import { ControllerParams, RootStore } from '@/components/DonationForm/types';
import {
  DonationCampaign,
  Frequency,
  GetDonationCampaignMetricsResponse,
} from '@wix/ambassador-donations-v1-donation-campaign/types';
import { ComponentIds as Ids } from '@/components/DonationForm/constants';
import { SPECS } from '@/common/constants/specs';
import { getCurrency, setupCurrencyFormatter } from '@/common/utils/currency';
import { getFrequencyLabel } from '@/common/utils/frequenciesToOptions';
import { GoalState } from './GoalState';
import { ILocaleKeys } from '@/locale-keys';

export interface FormState {
  frequency: string;
  comment: string;
  amount: number;
  reset(): void;
}

export class RootState {
  public campaign: DonationCampaign | undefined;
  public metrics: GetDonationCampaignMetricsResponse | undefined;
  public error: string | undefined;
  public isPremium: boolean;
  public showPremiumError: boolean;
  public siteCurrencyCode: string;
  public userInputAmount: string | undefined;
  public userInputFrequency: Frequency | undefined;
  public donateButtonLabelBackup: string | null = null;
  public goal = GoalState(this);

  constructor(
    public readonly rootStore: Omit<RootStore, '$state'>,
    private readonly $props: ControllerParams['$props'],
    private readonly widgetProps: ControllerParams['$widget']['props'],
    private readonly flowAPI: ControllerParams['flowAPI'],
    private readonly controllerConfig: ControllerParams['controllerConfig'],
  ) {
    this.campaign = undefined;
    this.error = undefined;
    this.isPremium = false;
    this.showPremiumError = false;
    this.siteCurrencyCode = getCurrency(this.controllerConfig);
    this.userInputAmount = undefined;
    this.userInputFrequency = undefined;
    this.donateButtonLabelBackup = null;
    makeAutoObservable(this, {
      goal: false,
    });
  }

  public reset(
    campaign?: DonationCampaign,
    isViewer: boolean = false,
    isPremium = false,
    metrics?: GetDonationCampaignMetricsResponse,
  ) {
    this.campaign = campaign;
    this.isPremium = isPremium;
    this.rootStore.$w(Ids.Amount)?.reset();
    this.rootStore.$w(Ids.Frequency)?.reset();
    this.rootStore.$w(Ids.Comment)?.reset();
    this.error = undefined;
    this.donateButtonLabelBackup = null;
    this.metrics = metrics;

    if (!isViewer && !this.isPremium) {
      this.showPremiumError = true;
    }
  }

  public get selectedFormattedAmount() {
    const selectedAmount = this.userInputAmount ?? this.amountDefaultValue;

    const currencyFormatter = setupCurrencyFormatter({
      getCurrencyFormatterFromFlowAPI: this.flowAPI.getCurrencyFormatter,
      wixCodeApi: this.controllerConfig.wixCodeApi,
    });
    return selectedAmount
      ? currencyFormatter({
          value: selectedAmount,
          currency: this.siteCurrencyCode,
        })
      : undefined;
  }

  public get selectedTranslatedFrequency() {
    const selectedFrequency =
      this.userInputFrequency ?? this.frequencyDefaultValue;
    const isSelectedFrequencyOneTime = selectedFrequency === Frequency.ONE_TIME;
    return this.selectedFormattedAmount && !isSelectedFrequencyOneTime
      ? getFrequencyLabel(selectedFrequency, this.rootStore.localeKeys)
      : undefined;
  }

  public get campaignId() {
    return this.$props.campaignId ?? this.widgetProps.campaignId;
  }

  public get isArchived() {
    return !!(this.campaign && this.campaign.active === false);
  }

  public get amountDefaultValue() {
    const defaultValueFromSettings =
      this.$props.amountDefaultValue ?? this.widgetProps.amountDefaultValue;

    const doesDefaultValueExists =
      !!this.campaign?.suggestedAmounts?.amounts?.find(
        (amount) => amount?.amount?.amount === defaultValueFromSettings,
      );

    return doesDefaultValueExists
      ? defaultValueFromSettings
      : this.campaign?.suggestedAmounts?.amounts?.[0]?.amount?.amount || '';
  }

  public get frequencyDefaultValue() {
    const defaultValueFromSettings =
      this.$props.frequencyDefaultValue ??
      this.widgetProps.frequencyDefaultValue;

    const doesDefaultValueExists =
      !!this.campaign?.recurringDonation?.frequencies?.find(
        (value) => value === defaultValueFromSettings,
      );

    return doesDefaultValueExists
      ? defaultValueFromSettings
      : this.campaign?.recurringDonation?.frequencies?.[0] || '';
  }

  public get isNotFound() {
    return !this.campaign;
  }

  public get shouldShowEmptyState() {
    return this.isArchived || this.isNotFound;
  }

  get isSuggestedAmountEnabled() {
    return !!this.campaign?.suggestedAmounts?.enabled;
  }

  get isCustomAmountEnabled() {
    return !!this.campaign?.customAmount?.enabled;
  }

  get isFrequencyEnabled() {
    return !!this.campaign?.recurringDonation?.enabled;
  }

  get isCommentEnabled() {
    return !!this.campaign?.enableComments;
  }

  get isGoalEnabled() {
    return (
      this.rootStore.experiments.enabled(SPECS.CampaignGoal) &&
      this.rootStore.experiments.enabled(SPECS.CampaignGoalUoU) &&
      !!this.campaign?.campaignGoalEnabled
    );
  }

  get shouldDeleteFrequencyWidget() {
    const isOneFrequencyOptionFormDisplayEnabled =
      this.flowAPI.experiments.enabled(SPECS.oneFrequencyOptionFormDisplay);
    const hasOnlyOneFrequencyOption =
      this.campaign?.recurringDonation?.frequencies?.length === 1;
    return isOneFrequencyOptionFormDisplayEnabled && hasOnlyOneFrequencyOption;
  }

  get deadline() {
    return this.campaign?.campaignGoal?.deadline;
  }

  get isTargetReached() {
    const { campaign, metrics } = this;
    if (!campaign || !metrics) {
      return false;
    }
    return (
      Number(metrics.totalAmount?.amount ?? 0) >=
      Number(campaign.campaignGoal?.targetAmount?.amount ?? 0)
    );
  }

  get isDeadlinePassed() {
    const { deadline } = this;
    return deadline ? deadline < new Date() : false;
  }
}
