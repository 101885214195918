import { makeAutoObservable } from 'mobx';
import { getInitialData } from './getInitialData';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  DonationCampaign,
  GetDonationCampaignMetricsResponse,
} from '@wix/ambassador-donations-v1-donation-campaign/types';

export type StandaloneGoalState = {
  campaign: DonationCampaign | undefined;
  metrics: GetDonationCampaignMetricsResponse | undefined;
};

export const createStateWithInitialData = async ({
  $props,
  $widget,
  httpClient,
}: {
  $props: { campaignId: string };
  $widget: { props: { campaignId: string } };
  httpClient: IHttpClient;
}) => {
  const campaignId = $props.campaignId ?? $widget.props.campaignId;
  const initialData = await getInitialData(httpClient, campaignId);
  const $state = makeAutoObservable({
    campaign: initialData.campaign,
    metrics: initialData.metrics,
    reset: (data) => {
      $state.campaign = data.campaign;
      $state.metrics = data.metrics;
    },
  });
  return $state;
};
