import { makeAutoObservable } from 'mobx';
import { differenceInDays, differenceInHours, isSameDay } from 'date-fns';
import { RootState } from './RootState';

export type IGoalState = ReturnType<typeof GoalState>;

export const GoalState = ($state: RootState) => {
  const { localeKeys } = $state.rootStore;
  return makeAutoObservable({
    get isSameDayAsDeadline() {
      return $state.deadline ? isSameDay($state.deadline, new Date()) : false;
    },
    get numOfDaysLeft() {
      if (!$state.deadline || $state.isDeadlinePassed) {
        return 0;
      }
      return differenceInDays($state.deadline, new Date()) + 1;
    },
    get numOfHoursLeft() {
      if (!$state.deadline || $state.isDeadlinePassed) {
        return 0;
      }
      return differenceInHours($state.deadline, new Date()) + 1;
    },
    get timeLeftText() {
      return this.isSameDayAsDeadline
        ? localeKeys.donations.widget.progressBar.dynamicHoursLeft({
            numHours: this.numOfHoursLeft,
          } as any)
        : localeKeys.donations.widget.progressBar.dynamicDaysLeft({
            numDays: this.numOfDaysLeft,
          } as any);
    },
    get targetAmount() {
      return Number($state.campaign?.campaignGoal?.targetAmount?.amount ?? 0);
    },
    get formattedTargetAmount() {
      return $state.campaign?.campaignGoal?.targetAmount?.formattedAmount;
    },
    get totalAmount() {
      return Number($state.metrics?.totalAmount?.amount ?? 0);
    },
    get formattedTotalAmount() {
      return $state.metrics?.totalAmount?.formattedAmount;
    },
    get donationCount() {
      return $state.metrics?.donationCount ?? 0;
    },
  });
};
