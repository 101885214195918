import { getInitialData } from './services/getInitialData';
import model from './model';
import { createStateWithInitialData } from './services/createStateWithInitialData';

export default model.createController(({ flowAPI, $props, $widget }) => {
  return {
    pageReady: async () => {
      const httpClient = flowAPI.essentials.httpClient;
      const $state = await createStateWithInitialData({
        httpClient,
        $props,
        $widget,
      });

      const resetStateData = async () => {
        const updatedCampaignId = $props.campaignId ?? $widget.props.campaignId;
        const updaedData = await getInitialData(httpClient, updatedCampaignId);
        $state.reset(updaedData);
      };
      $widget.onPropsChanged(resetStateData);
    },
    exports: {},
  };
});
